@charset "utf-8";
/* TOC

- Table of Contents (Index)
- Panel
- Shadows
- Alerts
- Breadcrumb
- Button
- Side-Nav
- Accordion
- Lazy Load XT
- Frontpage Widget

*/



/* Table of Contents (Index)
------------------------------------------------------------------- */

#toc ul,
#toc ul ul,
#toc ul ul ul, {
    list-style: none;
    margin-left: 30px;
}
#toc ul {
    margin-left: 0;
    margin-top: $spacing-unit;
}



/* Panel
------------------------------------------------------------------- */

.border-dotted {
  border: 1px dotted $grey-5;
  padding: rem-calc(20);
  border-radius: $global-radius;
}



/* Shadows
------------------------------------------------------------------- */

.shadow-no      {text-shadow: rgba(0, 0, 0, 0) 0 0 0;}
.shadow-black   {text-shadow: rgba(0, 0, 0, 0.498039) 0px 1px 2px;}
.shadow-white   {text-shadow: rgba(255, 255, 255, 0.498039) 0px 1px 2px;}



/* Alerts
------------------------------------------------------------------- */

.alert-box {
  font-family: $font-family-sans-serif;
  text-shadow: 0px 1px 1px rgba(0,0,0,0.9);
}
  .alert-box p {
    margin-bottom: 0;
  }
  .alert-box a {
    text-shadow: 1px 1px 0px rgba(0, 0, 0, 1);
    color: #fff;
    border-bottom: 1px dotted #fff;
  }
  .alert-box a:hover {
    border-bottom: 1px solid #fff;
  }
  .alert-box.terminal {
    background: $grey-12; 
    color: #fff; 
    border-color: scale-color($grey-12, $lightness: -14%);
    font-family: $font-family-monospace;
  }
  .alert-box.terminal::before {
    content: "$ ";
    color: $ci-6;
    float: left;
    margin: .25em .5em 0 0;
  }
  .alert-box.text {
    background-color: $grey-2;
    text-shadow: 0px 0px 0px rgba(0,0,0,0.9);
    border-color: scale-color($grey-2, $lightness: -14%);
    color: $grey-12;
  }



/* Button
------------------------------------------------------------------- */

button, .button         { letter-spacing: 1px; }
  button.grey, .button.grey { background: $grey-10; }
  button.grey:hover,
  button.grey:focus,
  .button.grey:hover,
  .button.grey:focus      { background-color: $grey-16; }



/* Side-Nav
------------------------------------------------------------------- */

.side-nav li.title { text-transform: uppercase;}
.side-nav li { border-top: 1px solid $grey-3;}
.side-nav li a:not(.button) { border-bottom: 0; padding: 0.4375rem 0rem; }
.side-nav li a:not(.button):hover, .side-nav li a:not(.button):focus { background: $grey-1; }

.homepage p { margin: 0; padding: 0; color: $grey-10; }



/* Accordion
------------------------------------------------------------------- */

dl.accordion  { border-top: 1px solid $grey-2;  }
.accordion dd   { border-bottom: 1px solid $grey-2;  }
dd.accordion-navigation span { padding-right: 12px; }
dd.accordion-navigation span:before { content: "\F107" }
dd.accordion-navigation.active span:before { content: "\F105" }
dd.accordion-navigation.active span:before { content: "\F105" }

/* Frontpage Widget
------------------------------------------------------------------- */

.medium-4.columns.frontpage-widget { 
    border-radius: 20px 0px 20px 0px;
    background-color: #EF8D8D;
    box-shadow: 10px 10px 5px #000000;
    transition: background-color 0.1s linear;
    background-image: url("https://d2vansag56dj8u.cloudfront.net/ShareX/2020/October/27/17/45/11/959/015ad6e5-7970-4a9a-ae96-35025a633853/arborinteractivecom_background_image_texture_nocolor.webp");
    background-repeat: repeat;
}
.medium-4.columns.frontpage-widget:hover { background-color: #DF7D7D; }
.medium-4.columns.frontpage-widget img {
    border-radius: 20px 0px 20px 0px;
    margin-top:15px;
    box-shadow: 4px 4px 8px #000000;
}

.frontpage-body {
    margin: 0 auto;
    max-width: 78.125rem
}

.frontpage-content-section {
    display: block;
    clear: both;
}

.frontpage-flex-section {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: flex-start;
    width: 100%;
    margin: 0 auto;
    justify-content: flex-start;
}

.frontpage-flex-container {
    padding: 12px 10px 12px 10px;
    box-sizing: border-box;
    width: 33%;
}

@media #{$medium-only} {
    .frontpage-flex-container {
      width: 50%;
    }
}

@media #{$small-only} {
    .frontpage-flex-container {
      width: 100%;
    }
}

.frontpage-flex-content {
    display: block;
    padding: 20px 2px 5px 2px;
    min-height: 400px;
    border-radius: 20px 0px 20px 0px;
    background-color: #EF8D8D;
    box-shadow: 10px 10px 5px #000000;
    transition: background-color 0.1s linear;
    background-image: url("https://d2vansag56dj8u.cloudfront.net/ShareX/2020/October/27/17/45/11/959/015ad6e5-7970-4a9a-ae96-35025a633853/arborinteractivecom_background_image_texture_nocolor.webp");
    background-repeat: repeat;
}
.frontpage-flex-content:hover { background-color: #DF7D7D; }

.frontpage-flex-content img { 
    display: block;
    margin: 0 auto;
    border-radius: 20px 0px 20px 0px;
    box-shadow: 4px 4px 8px #000000;
  }

.frontpage-flex-content p,
.frontpage-flex-content h1,  
.frontpage-flex-content h2,
.frontpage-flex-content h3,
.frontpage-flex-content h4,   
{
  display: block;
  margin: 5px;
}


/* Lazy Load XT
------------------------------------------------------------------- */

/*! Lazy Load XT v1.0.6 2014-11-19
 * http://ressio.github.io/lazy-load-xt
 * (C) 2014 RESS.io
 * Licensed under MIT */
img.lazy {
  display: none;
}
.lazy-hidden {
    opacity: 0;
}
.lazy-loaded {
    -webkit-transition: opacity 0.7s;
    -moz-transition: opacity 0.7s;
    -ms-transition: opacity 0.7s;
    -o-transition: opacity 0.7s;
    transition: opacity 0.7s;
    opacity: 1;
}

*:target:not([id^='fn:']):not([id^='fnref:']) {
  &::before {
    content: " ";
    width: 0;
    height: 0;

    display: block;
    padding-top: 50px;
    margin-top: -50px;
  }
}
